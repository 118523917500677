import React from "react";

import { Icon } from '@types';

const GoogleAdsIcon: Icon = ({ fill = "white", ...props }) => (
    <svg { ...props } version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 250.8 312.8" style={{enableBackground: 'new 0 0 250.8 312.8'}} xmlSpace="preserve">
    {/*<style type="text/css">
        .st0{fill:#3C8BD9;}
        .st1{fill:#FABC04;}
        .st2{fill:#34A852;}
        .st3{fill:#E1C025;}
        .st4{fill:#757575;}
    </style>*/}
    <g>
        <path style={{fill: '#3C8BD9'}} d="M85.9,28.6c2.4-6.3,5.7-12.1,10.6-16.8c19.6-19.1,52-14.3,65.3,9.7c10,18.2,20.6,36,30.9,54
            c17.2,29.9,34.6,59.8,51.6,89.8c14.3,25.1-1.2,56.8-29.6,61.1c-17.4,2.6-33.7-5.4-42.7-21c-15.1-26.3-30.3-52.6-45.4-78.8
            c-0.3-0.6-0.7-1.1-1.1-1.6c-1.6-1.3-2.3-3.2-3.3-4.9c-6.7-11.8-13.6-23.5-20.3-35.2c-4.3-7.6-8.8-15.1-13.1-22.7
            c-3.9-6.8-5.7-14.2-5.5-22C83.6,36.2,84.1,32.2,85.9,28.6"/>
        <path style={{fill: '#FABC04'}} d="M85.9,28.6c-0.9,3.6-1.7,7.2-1.9,11c-0.3,8.4,1.8,16.2,6,23.5C101,82,112,101,122.9,120c1,1.7,1.8,3.4,2.8,5
            c-6,10.4-12,20.7-18.1,31.1c-8.4,14.5-16.8,29.1-25.3,43.6c-0.4,0-0.5-0.2-0.6-0.5c-0.1-0.8,0.2-1.5,0.4-2.3
            c4.1-15,0.7-28.3-9.6-39.7c-6.3-6.9-14.3-10.8-23.5-12.1c-12-1.7-22.6,1.4-32.1,8.9c-1.7,1.3-2.8,3.2-4.8,4.2
            c-0.4,0-0.6-0.2-0.7-0.5c4.8-8.3,9.5-16.6,14.3-24.9C45.5,98.4,65.3,64,85.2,29.7C85.4,29.3,85.7,29,85.9,28.6"/>
        <path style={{fill: '#34A852'}} d="M11.8,158c1.9-1.7,3.7-3.5,5.7-5.1c24.3-19.2,60.8-5.3,66.1,25.1c1.3,7.3,0.6,14.3-1.6,21.3
            c-0.1,0.6-0.2,1.1-0.4,1.7c-0.9,1.6-1.7,3.3-2.7,4.9c-8.9,14.7-22,22-39.2,20.9C20,225.4,4.5,210.6,1.8,191
            c-1.3-9.5,0.6-18.4,5.5-26.6c1-1.8,2.2-3.4,3.3-5.2C11.1,158.8,10.9,158,11.8,158"/>
        <path style={{fill: '#FABC04'}} d="M11.8,158c-0.4,0.4-0.4,1.1-1.1,1.2c-0.1-0.7,0.3-1.1,0.7-1.6L11.8,158"/>
        <path style={{fill: '#E1C025'}} d="M81.6,201c-0.4-0.7,0-1.2,0.4-1.7c0.1,0.1,0.3,0.3,0.4,0.4L81.6,201"/>
        <path style={{fill: '#757575'}} d="M20.7,260.4C9.4,260.4,0,269.5,0,280.7s9.4,20.2,20.7,20.2c6.1,0,10.6-2,14.2-5.7c3.7-3.7,4.8-8.8,4.8-12.9
            c0-1.3-0.1-2.5-0.3-3.5H20.7v5.5H34c-0.4,3.1-1.4,5.4-3,7c-1.9,1.9-5,4.1-10.3,4.1c-8.2,0-14.6-6.6-14.6-14.7S12.5,266,20.7,266
            c4.4,0,7.7,1.7,10,4l3.9-3.9C31.3,262.9,26.9,260.4,20.7,260.4z M54.6,274.9c-7.1,0-13,5.5-13,13.2c0,7.6,5.8,13.2,13,13.2
            s13-5.6,13-13.2S61.7,274.9,54.6,274.9 M54.6,296.1c-3.9,0-7.3-3.3-7.3-8s3.4-8,7.3-8s7.3,3.2,7.3,8
            C61.9,292.8,58.5,296.1,54.6,296.1 M82.8,274.9c-7.2,0-13,5.5-13,13.2c0,7.6,5.8,13.2,13,13.2c7.1,0,13-5.6,13-13.2
            S90,274.9,82.8,274.9 M82.8,296.1c-3.9,0-7.3-3.3-7.3-8s3.4-8,7.3-8s7.3,3.2,7.3,8C90.1,292.8,86.8,296.1,82.8,296.1 M110.9,274.9
            c-6.5,0-12.4,5.7-12.4,13.1c0,7.3,5.9,13,12.4,13c3.1,0,5.5-1.4,6.8-3h0.2v1.9c0,5-2.6,7.7-6.9,7.7c-3.5,0-5.6-2.5-6.5-4.6l-5,2.1
            c1.4,3.5,5.2,7.7,11.5,7.7c6.7,0,12.3-4,12.3-13.6v-23.4h-5.4v2.1h-0.2C116.4,276.3,114,274.9,110.9,274.9 M111.4,295.9
            c-3.9,0-7.2-3.3-7.2-7.9s3.3-8,7.2-8c3.9,0,6.9,3.4,6.9,8S115.3,295.9,111.4,295.9 M127.5,262h5.7v38.6h-5.7V262z M148.5,274.9
            c-6.8,0-12.5,5.3-12.5,13.2c0,7.4,5.6,13.2,13.1,13.2c6.1,0,9.6-3.7,11-5.8l-4.5-3c-1.5,2.2-3.6,3.6-6.5,3.6c-3,0-5.1-1.3-6.4-4
            l17.7-7.3l-0.6-1.5C158.7,280.4,155.3,274.9,148.5,274.9 M141.7,287.7c-0.2-5.1,4-7.7,6.9-7.7c2.3,0,4.3,1.1,4.9,2.8L141.7,287.7z"
            />
        <path style={{fill: '#757575'}} d="M170.4,300l13.2-35.1h5.1l13.2,35.1h-5l-3.4-9.5h-14.7l-3.4,9.5H170.4z M180.3,286.3H192l-5.7-15.8h-0.2
            L180.3,286.3z"/>
        <path style={{fill: '#757575'}} d="M206.5,297.1c-2.2-2.5-3.3-5.5-3.3-9.1c0-3.5,1.1-6.5,3.3-9.1c2.3-2.5,4.9-3.7,8.1-3.7c1.8,0,3.4,0.4,4.8,1.1
            c1.5,0.8,2.6,1.7,3.4,3h0.2l-0.2-3.3v-11.1h4.5V300H223v-3.3h-0.2c-0.8,1.2-1.9,2.2-3.4,3s-3.1,1.1-4.8,1.1
            C211.4,300.8,208.7,299.6,206.5,297.1 M220.7,294.3c1.5-1.6,2.2-3.7,2.2-6.3c0-2.5-0.7-4.6-2.2-6.2c-1.4-1.6-3.2-2.4-5.4-2.4
            s-4,0.8-5.4,2.4c-1.5,1.6-2.2,3.7-2.2,6.2s0.7,4.6,2.2,6.2s3.3,2.4,5.4,2.4C217.5,296.7,219.3,295.9,220.7,294.3"/>
        <path style={{fill: '#757575'}} d="M248.1,298.6c-1.8,1.4-4.1,2.2-6.9,2.2c-2.4,0-4.5-0.6-6.4-1.9c-1.8-1.3-3.1-2.9-3.9-5l4-1.7
            c0.6,1.4,1.4,2.6,2.6,3.4c1.1,0.8,2.4,1.2,3.7,1.2c1.4,0,2.6-0.3,3.6-0.9s1.4-1.4,1.4-2.2c0-1.5-1.2-2.7-3.5-3.4l-4.1-1
            c-4.7-1.2-7-3.4-7-6.8c0-2.2,0.9-3.9,2.7-5.3c1.8-1.3,4.1-2,6.8-2c2.1,0,4,0.5,5.8,1.5c1.7,1,2.9,2.4,3.6,4.1l-4,1.7
            c-0.5-1-1.2-1.8-2.2-2.4s-2.2-0.9-3.5-0.9c-1.2,0-2.2,0.3-3.2,0.9c-0.9,0.6-1.4,1.3-1.4,2.2c0,1.4,1.3,2.4,3.9,2.9l3.6,0.9
            c4.8,1.2,7.1,3.6,7.1,7.2C250.9,295.4,249.9,297.2,248.1,298.6"/>
    </g>
    </svg>

);

export default GoogleAdsIcon;

import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import Wrapper from '@components/Wrapper';
import Section from '@components/Section';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import Transitions from '@components/Transitions';
import GridCustom from '@components/Grid/Grid.Custom';
import HeroWrapper from '@components/Hero/Hero.Wrapper';
import LinkContainer from '@components/Link/Link.Container';
import Wave from '@components/Wave';

import Image from '../../components/Image';

import TranslatedSectionTemplate from '@types';

const ServiceIntroduction: TranslatedSectionTemplate = ({ sectionContext, translations }) => {
    const intl = useIntl();
    const bigLogo = translations.bigLogo ? translations.bigLogo : false;
    return (
        <Section {...sectionContext}>
            <Wave top waveContext={{ gradientBg: true }} />

            <Transitions.CSS.FadeIn>
                <Wrapper zIndex="1">
                    <StyledGridCustom columns="55fr 45fr" rows="auto" columnGap="30px" rowGap="40px">
                        <Transitions.CSS.FadeIn>
                            <TextContainer>
                                <Headings.h2>{ translations.title.text }</Headings.h2>
                                { translations.description &&
                                <Paragraph lead>{ translations.description.text }</Paragraph>
                                }
                            </TextContainer>
                        </Transitions.CSS.FadeIn>
                        <Transitions.CSS.FadeInZoom as={FloatingImages} delay="0.5">
                            <FloatingImagesContainer>
                                {translations.logos.map(logo => {
                                    const Icon = logo.Icon;
                                    return (
                                        <Transitions.CSS.Float as={FloatingImage} bigLogo={bigLogo}>
                                            <Icon />
                                        </Transitions.CSS.Float>
                                    );
                                })}
                        </FloatingImagesContainer>
                        </Transitions.CSS.FadeInZoom>
                    </StyledGridCustom>
                </Wrapper>
            </Transitions.CSS.FadeIn>
        </Section>
    );
};

const StyledGridCustom = styled(GridCustom)`
    ${mediaqueries.tablet_ipad`
        grid-template-columns: 1fr;

        & p {
            font-size: 18px;
        }
    `};
`;

const Heading = styled(Headings.h1)`
    font-weight: 800;
`;

const TextContainer = styled.div`
    max-width: 550px;
    text-align: center;

    ${mediaqueries.from_tablet_ipad`
        max-width: unset;
        text-align: left;
    `};
`;

const FloatingImages = styled.div`
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

const FloatingImagesContainer = styled.ul`
    height: auto;
    min-height: 270px;
    
    ${mediaqueries.from_mobile_i6`
        min-height: 300px;
        max-width: 450px;
    `};

    ${mediaqueries.from_mobile_i6p`
        width: 90%;
    `};

    ${mediaqueries.from_tablet`
        width: 100%;
        margin: 0 auto;
    `};

    ${mediaqueries.from_tablet_ipad`
        max-width: unset;
        width: 100%;
        height: auto;
        min-height: unset;
    `};

`;

const FloatingImage = styled.li<{ blur?: number, bigLogo?: boolean }>`
    position: absolute;
    ${p => p.bigLogo ? `width: 50px;` : `width: 50px;`}
    // width: 50px;
    height: auto;
    top: 0;
    left: 0;
    margin: 0;
    border-radius: 0.25em;
    will-change: transform;

    // As in .floating-image
    // Animation is not defined because this component it's going into
    // <Transition.CSS.Float as={ FloatingImage }> as the 'as' property
    animation-duration: 6s;
    transition: .3s;
    // filter: blur(0px);

    ${p => p.blur && `filter: blur(${p.blur}px);`};

    & svg {
        width: 100%;
        height: 100%;
        // box-shadow: 0 10px 30px rgba(0, 0, 0, .08); // lighter box-shadow
        // box-shadow: 0 15px 30px rgba(0, 0, 0, 0.14), 0 15px 30px rgba(0, 0, 0, 0.14); // stronger box-shadow
        // border-radius: 100%;
        overflow: visible;
    }

    &:nth-of-type(1) {
        ${p => p.bigLogo === true ? `width: 60px;` : `width: 60px;`};
        // width: 60px;
        left: auto;
        right: 10%;
        top: 15px;
        // filter: blur(0.5px);

        ${p => p.bigLogo === true ? mediaqueries.from_mobile_i6`width: 70px;` : mediaqueries.from_mobile_i6`width: 70px;`};
        ${mediaqueries.from_mobile_i6`
            // width: 70px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_tablet_ipad`width: 80px;` : mediaqueries.from_tablet_ipad`width: 80px;`};
        ${mediaqueries.from_tablet_ipad`
            // width: 80px;
            top: 15px;
            right: 30px;
            left: auto;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_desktop`width: 80px;` : mediaqueries.from_desktop`width: 80px;`};
        ${mediaqueries.from_desktop`
            // width: 80px;
            top: 0;
            right: 70px;
            left: auto;
        `};
    };

    &:nth-of-type(2) {
        ${p => p.bigLogo === true ? `width: 70px;` : `width: 70px;`};
        // width: 70px;
        right: auto;
        left: 6%;
        top: 40px;
        animation-delay: -5s;
        // filter: blur(2px);

        ${p => p.bigLogo === true ? mediaqueries.from_mobile_i6`width: 80px;` : mediaqueries.from_mobile_i6`width: 80px;`};
        ${mediaqueries.from_mobile_i6`
            // width: 80px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_tablet_ipad`width: 100px;` : mediaqueries.from_tablet_ipad`width: 100px;`};
        ${mediaqueries.from_tablet_ipad`
            // width: 100px;
            top: 57px;
            right: 138px;
            left: auto;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_desktop`width: 120px;` : mediaqueries.from_desktop`width: 120px;`};
        ${mediaqueries.from_desktop`
            // width: 120px;
            top: 57px;
            right: 185px;
            left: auto;
        `};
    };

    &:nth-of-type(3) {
        ${p => p.bigLogo === true ? `width: 70px;` : `width: 70px;`};
        // width: 70px;
        left: auto;
        right: 15%;
        top: 90px;
        animation-delay: -3s;
        // filter: blur(2px);

        ${p => p.bigLogo === true ? mediaqueries.from_mobile_i6`width: 80px;` : mediaqueries.from_mobile_i6`width: 80px;`};
        ${mediaqueries.from_mobile_i6`
            // width: 80px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_tablet_ipad`width: 90px;` : mediaqueries.from_tablet_ipad`width: 90px;`};
        ${mediaqueries.from_tablet_ipad`
            // width: 90px;
            top: 114px;
            right: 40px;
            left: auto;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_desktop`width: 100px;` : mediaqueries.from_desktop`width: 100px;`};
        ${mediaqueries.from_desktop`
            width: 100px;
            top: 114px;
            right: 80px;
            left: auto;
        `};
    };

    &:nth-of-type(4) {
        ${p => p.bigLogo === true ? `width: 80px;` : `width: 80px;`};
        // width: 80px;
        right: 25%;
        left: auto;
        top: 170px;
        animation-delay: -4.5s;

        ${p => p.bigLogo === true ? mediaqueries.from_mobile_i6`width: 80px;` : mediaqueries.from_mobile_i6`width: 80px;`};
        ${mediaqueries.from_mobile_i6`
            // width: 80px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_tablet_ipad`width: 90px;` : mediaqueries.from_tablet_ipad`width: 90px;`};
        ${mediaqueries.from_tablet_ipad`
            // width: 90px;
            left: auto;
            right: 0px;
            top: 216px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_desktop`width: 100px;` : mediaqueries.from_desktop`width: 100px;`};
        ${mediaqueries.from_desktop`
            // width: 100px;
            left: auto;
            right: 30px;
            top: 216px;
        `};
    };

    &:nth-of-type(5) {
        ${p => p.bigLogo === true ? `width: 65px;` : `width: 65px;`};
        // width: 65px;
        left: calc(50% - 40px);
        top: 0;
        animation-delay: -1.8s;
        // filter: blur(2px);

        ${p => p.bigLogo === true ? mediaqueries.from_mobile_i6`width: 75px;` : mediaqueries.from_mobile_i6`width: 75px;`};
        ${mediaqueries.from_mobile_i6`
            // width: 75px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_tablet_ipad`width: 90px;` : mediaqueries.from_tablet_ipad`width: 90px;`};
        ${mediaqueries.from_tablet_ipad`
            // width: 90px;
            top: 75px;
            right: 245px;
            left: auto;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_desktop`width: 100px;` : mediaqueries.from_desktop`width: 100px;`};
        ${mediaqueries.from_desktop`
            // width: 100px;
            top: 54px;
            right: 305px;
            left: auto;
        `};
    };

    &:nth-of-type(6) {
        ${p => p.bigLogo === true ? `width: 80px;` : `width: 80px;`};
        // width: 80px;
        right: auto;
        left: 32%;
        top: 80px;
        animation-delay: -1.6s;

        ${p => p.bigLogo === true ? mediaqueries.from_mobile_i6`width: 90px;` : mediaqueries.from_mobile_i6`width: 90px;`};
        ${mediaqueries.from_mobile_i6`
            // width: 90px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_tablet_ipad`width: 100px;` : mediaqueries.from_tablet_ipad`width: 100px;`};
        ${mediaqueries.from_tablet_ipad`
            // width: 100px;
            top: 167px;
            left: auto;
            right: 140px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_desktop`width: 120px;` : mediaqueries.from_desktop`width: 120px;`};
        ${mediaqueries.from_desktop`
            // width: 120px;
            top: 167px;
            left: auto;
            right: 200px;
        `};
    }

    &:nth-of-type(7) {
        ${p => p.bigLogo === true ? `width: 94px;` : `width: 94px;`};
        // width: 94px;
        left: 10px;
        right: auto;
        top: 140px;
        animation-delay: -3.6s;

        ${p => p.bigLogo === true ? mediaqueries.from_mobile_i6`width: 85px;` : mediaqueries.from_mobile_i6`width: 95px;`};
        ${mediaqueries.from_mobile_i6`
            // width: 95px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_tablet_ipad`width: 90px;` : mediaqueries.from_tablet_ipad`width: 100px;`};
        ${mediaqueries.from_tablet_ipad`
            // width: 100px;
            right: 245px;
            left: auto;
            top: 225px;
        `};

        ${p => p.bigLogo === true ? mediaqueries.from_desktop`width: 95px;` : mediaqueries.from_desktop`width: 110px;`};
        ${mediaqueries.from_desktop`
            // width: 110px;
            right: 325px;
            left: auto;
            top: 222px;
        `};
    };
`;

export default ServiceIntroduction;
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/seo';

import Layout from '@components/Layout';
import OnlineMarketingHero from '@sections/service/Service.Hero';
import OnlineMarketingIntroduction from '@sections/service/Service.Introduction';
import OnlineMarketingFeatures from '@sections/service/Service.Features';
import OnlineMarketingServices from '@sections/service/Service.Services';
import OnlineMarketingCTA from '@sections/CTA/CTA.Main';
// import OnlineMarketingStrategy from '@sections/service/Service.Strategy';

import '../components/Utils';

import { useThemeUI } from 'theme-ui';

// import Icons from '@icons';
import StatusUpdate from '@icons/undraw/StatusUpdate.Icon';

import SocialShare from '@icons/undraw/SocialShare.Icon';
import SuccessFactors from '@icons/undraw/SuccessFactors.Icon';
import Goals from '@icons/undraw/Goals.Icon';
import SocialDashboard from '@icons/undraw/SocialDashboard.Icon';
import SocialInfluencer from '@icons/undraw/SocialInfluencer.Icon';
import PersonalSettings from '@icons/undraw/PersonalSettings.Icon';

import Linkedin2Logo from '@icons/logo/Linkedin2.Icon';
import GoogleAnalyticsLogo from '@icons/logo/GoogleAnalytics.Icon';
import SnapchatLogo from '@icons/logo/Snapchat.Icon';
import GoogleAdsLogo from '@icons/logo/GoogleAds.Icon';
import GoogleMarketingPlatformLogo from '@icons/logo/GoogleMarketingPlatform.Icon';
import FacebookLogo from '@icons/logo/Facebook.Icon';
import InstagramLogo from '@icons/logo/Instagram.Icon';

const OnlineMarketingPage: React.FC = () => {
    const intl = useIntl();
    const invertHeader = true;

    const context = useThemeUI();
    const { theme } = context;
    const fillColor = theme.colors.gradientBg;

    const HeroTranslations = {
        title: {
            text: intl.formatMessage({ id: 'marketing-title' }),
        },
        subtitle: {
            text: intl.formatMessage({ id: 'marketing-subtitle' }),
        },
        description: {
            text: intl.formatMessage({ id: 'marketing-description' }),
        },
        Link1: {
            text: intl.formatMessage({ id: 'marketing-button-discover' }),
            link: '/online-marketing/#work'
        },
        Link2: {
            text: intl.formatMessage({ id: 'marketing-button-contact' }),
            link: '/contact/'
        },
        MainIcon: {
            // Icon: Goals
            // Icon: MobileMarketing
            // Icon: SocialDashboard
            Icon: StatusUpdate,
            fill: fillColor,
        }
    }

    const IntroductionTranslations = {
        title: {
            text: intl.formatMessage({ id: 'marketing-introduction-title' })
        },
        description: {
            text: intl.formatMessage({ id: 'marketing-introduction-description' })
        },
        logos: [
            { Icon: Linkedin2Logo },
            { Icon: GoogleAnalyticsLogo },
            { Icon: SnapchatLogo },
            { Icon: GoogleAdsLogo },
            { Icon: GoogleMarketingPlatformLogo },
            { Icon: FacebookLogo },
            { Icon: InstagramLogo },
        ]
    }

     const FeatureTranslations = {
        wave: false,
        columns: 3,
        title: {
            text: intl.formatMessage({ id: 'marketing-features-title' })
        },
        subtitle: {
            text: intl.formatMessage({ id: 'marketing-features-subtitle' })
        },
        features: [
            {
                title: intl.formatMessage({ id: 'marketing-features-1' }),
                description: intl.formatMessage({ id: 'marketing-features-1-desc' }),
                Icon: SocialShare,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'marketing-features-2' }),
                description: intl.formatMessage({ id: 'marketing-features-2-desc' }),
                Icon: SuccessFactors,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'marketing-features-3' }),
                description: intl.formatMessage({ id: 'marketing-features-3-desc' }),
                Icon: Goals,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'marketing-features-4' }),
                description: intl.formatMessage({ id: 'marketing-features-4-desc' }),
                Icon: SocialDashboard,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'marketing-features-5' }),
                description: intl.formatMessage({ id: 'marketing-features-5-desc' }),
                Icon: SocialInfluencer,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'marketing-features-6' }),
                description: intl.formatMessage({ id: 'marketing-features-6-desc' }),
                Icon: PersonalSettings,
                fill: fillColor,
            },
        ],
    };

    const ServicesTranslations = {
        title: {
            text: intl.formatMessage({ id: 'marketing-services-title' })
        },
        typeformButton: {
            text: intl.formatMessage({ id: 'web-services-button' }),
        },
        services: [
            { 
                title: { text: intl.formatMessage({ id: 'marketing-services-1' }) },
                description: { text: intl.formatMessage({ id: 'marketing-services-1-desc' }) },
                Image: { name: 'analytics-laptop.jpg' },
                // Icon: ReactLogo
            },
            { 
                title: { text: intl.formatMessage({ id: 'marketing-services-2' }) },
                description: { text: intl.formatMessage({ id: 'marketing-services-2-desc' }) },
                Image: { name: 'facebook-marketing.jpg' },
            },
            { 
                title: { text: intl.formatMessage({ id: 'marketing-services-3' }) },
                description: { text: intl.formatMessage({ id: 'marketing-services-3-desc' }) },
                Image: { name: 'instagram-page.jpg' },
            },
        ]
    };

    return (
    <Layout id="marketing" invertHeader={invertHeader}>
        <SEO
            /*lang={intl.locale}*/
            lang={intl.formatMessage({ id: 'current' })}
            title={intl.formatMessage({ id: 'marketing-meta-title' })}
            description={intl.formatMessage({ id: 'marketing-meta-description'})}
            slug='/online-marketing/'
        />
        <OnlineMarketingHero translations={ HeroTranslations } />
        <OnlineMarketingIntroduction translations={ IntroductionTranslations } sectionContext={{ small: true }} />
        <OnlineMarketingFeatures translations={ FeatureTranslations } />
        <OnlineMarketingServices translations={ ServicesTranslations } sectionContext={{ small: true, noPaddingTop: true, noPaddingBottom: true, }} />
        <OnlineMarketingCTA sectionContext={{ small: true, gradientBg: false }} />
        {/*<OnlineMarketingStrategy sectionContext={{ gradientBg: true, }}/>*/}
    </Layout>
    )
}

export default OnlineMarketingPage;

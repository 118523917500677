import React from "react";

import { Icon } from '@types';

const SuccessFactorsIcon: Icon = ({ fill = '#c4c4c4', title = 'success_factors', ...props }) => (
<svg { ...props } id="a17dd300-7621-4978-a344-c06233c14e82" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="887.87606" height="652.02406" viewBox="0 0 887.87606 652.02406">
    <title>{ title }</title>
    <path d="M1043.93777,757.41073c-2.84541,25.87621-152.22584,23.704-151.99948-.00154C894.78367,731.53451,1044.1641,733.70675,1043.93777,757.41073Z" transform="translate(-156.06197 -123.98797)" fill="#e6e6e6" />
    <polygon points="785.259 567.02 779.204 580.14 768.102 579.131 761.038 555.918 772.139 549.863 785.259 567.02" fill="#ffb8b8" />
    <polygon points="823.372 597.237 823.611 606.38 806.454 607.39 806.454 596.288 823.372 597.237" fill="#ffb8b8" />
    <polygon points="817.555 430.772 813.518 511.511 823.611 599.316 793.333 603.353 774.158 492.336 768.102 416.642 817.555 430.772" fill="#2f2e41" />
    <path d="M1021.05174,525.49168s12.11093,79.7303-13.12018,105.97066-59.54542,72.66559-59.54542,72.66559L924.16428,673.8506l61.5639-65.60088L973.61725,563.843l-49.453-23.21262,8.074-55.50844,72.6656-1.00924Z" transform="translate(-156.06197 -123.98797)" fill="#2f2e41" />
    <circle cx="805.44435" cy="194.60862" r="22.20338" fill="#ffb8b8" />
    <path d="M980.682,323.64281s1.00925,31.28657,6.05547,35.32355-18.1664,21.19413-18.1664,21.19413l-15.13867-24.22186V333.73525Z" transform="translate(-156.06197 -123.98797)" fill="#ffb8b8" />
    <polygon points="814.528 242.043 826.781 223.541 833.703 232.96 843.796 261.219 835.722 371.226 788.287 372.236 782.232 246.08 797.37 227.914 814.528 242.043" fill="#d0cde1" />
    <path d="M919.11806,373.09578l-8.074-1.00924s-2.01849,1.00924-3.02773,8.07395-13.12018,69.63786-13.12018,69.63786l16.14791,76.70257,18.1664-24.22186L918.10881,459.8908l11.10169-42.38827Z" transform="translate(-156.06197 -123.98797)" fill="#2f2e41" />
    <polygon points="865.999 248.099 872.054 248.099 887.193 329.847 873.064 393.43 858.934 370.217 862.971 344.986 860.953 322.783 853.888 309.662 865.999 248.099" fill="#2f2e41" />
    <path d="M932.23823,700.091s6.05547-2.01849,6.05547,2.01849-4.037,16.14791-4.037,16.14791,6.05547,37.342-3.02773,35.32355-16.14791-19.17564-17.15716-24.22186-6.05546-31.28658-6.05546-31.28658-8.074-19.17564-3.02773-20.18488,19.17564-5.04622,19.17564-5.04622S916.09032,698.07246,932.23823,700.091Z" transform="translate(-156.06197 -123.98797)" fill="#2f2e41" />
    <path d="M961.50632,731.37753s16.14791-10.09245,19.17564-2.01849a84.80084,84.80084,0,0,1,4.037,18.1664s4.037,13.12017-12.11093,11.10169-14.12942-2.01849-15.13867-8.074S961.50632,731.37753,961.50632,731.37753Z" transform="translate(-156.06197 -123.98797)" fill="#2f2e41" />
    <path d="M974.6265,318.59659l-4.037-1.00925s-3.02773-17.15715-10.09244-15.13866-25.23111,4.037-25.23111-4.037,17.15715-15.13867,27.24959-14.12942,27.2496,4.037,26.24036,19.17564-7.52165,29.86536-7.52165,29.86536l-.52605-8.97562Z" transform="translate(-156.06197 -123.98797)" fill="#2f2e41" />
    <polygon points="797.37 254.154 814.528 242.043 797.37 227.914 791.414 235.062 797.37 254.154" fill="#d0cde1" />
    <polygon points="829.666 253.145 814.528 242.043 826.638 223.877 833.703 232.96 829.666 253.145" fill="#d0cde1" />
    <path d="M912.05335,372.08654l32.29582-13.12018,7.56933-5.55084,22.708,100.41981,15.13866-45.416-4.5416-58.03155,41.88364,21.69876-14.12942,68.62861-2.01849,26.24036,6.05547,21.19413s21.19413,15.13866,14.12942,31.28657-15.13867,17.15716-15.13867,17.15716-34.3143-32.29582-36.33279-40.36978-5.04622-22.20337-5.04622-22.20337-17.15716,64.59164-37.342,63.58239-20.18488-22.20337-20.18488-22.20337l5.04622-22.20338,8.07395-23.21262-4.037-38.35129Z" transform="translate(-156.06197 -123.98797)" fill="#2f2e41" />
    <rect x="0.30042" y="0.39886" width="703.57565" height="450.60114" fill="#e6e6e6" />
    <rect x="20.419" y="56.91548" width="663.33848" height="171.77293" fill="#fff" />
    <rect x="185.4182" y="81.72713" width="140.28123" height="8.05267" fill="#e6e6e6" />
    <rect x="185.4182" y="111.10108" width="216.62475" height="8.05267" fill={ fill } />
    <rect x="185.4182" y="138.77561" width="176.5444" height="8.05267" fill="#e6e6e6" />
    <rect x="185.4182" y="166.34556" width="103.06376" height="8.05267" fill="#e6e6e6" />
    <rect x="185.4182" y="193.9155" width="155.54993" height="8.05267" fill="#e6e6e6" />
    <rect x="121.4805" y="78.86424" width="17.17729" height="17.17729" fill="#e6e6e6" />
    <rect x="121.4805" y="106.53877" width="17.17729" height="17.17729" fill={ fill } />
    <rect x="121.4805" y="134.2133" width="17.17729" height="17.17729" fill="#e6e6e6" />
    <rect x="121.4805" y="161.88783" width="17.17729" height="17.17729" fill="#e6e6e6" />
    <rect x="121.4805" y="189.56235" width="17.17729" height="17.17729" fill="#e6e6e6" />
    <rect x="533.73553" y="117.9903" width="57.25764" height="57.25764" fill="#e6e6e6" />
    <rect x="20.419" y="252.54576" width="663.33848" height="171.77293" fill="#fff" />
    <rect x="151.06361" y="267.81206" width="17.17729" height="17.17729" fill="#e6e6e6" />
    <rect x="121.4805" y="267.81206" width="17.17729" height="17.17729" fill={ fill } />
    <path d="M568.78582,429.36336a65.04552,65.04552,0,0,0-105.003-9.69993l-4.18616-3.65794a70.59376,70.59376,0,0,1,113.973,10.5262Z" transform="translate(-156.06197 -123.98797)" fill="#e6e6e6" />
    <path d="M571.40549,501.78181l-4.6134-3.102a65.07767,65.07767,0,0,0,1.99373-69.31643l4.78387-2.83167a70.63741,70.63741,0,0,1-2.1642,75.25012Z" transform="translate(-156.06197 -123.98797)" fill={ fill } />
    <path d="M460.17592,509.52655a70.58952,70.58952,0,0,1-.57926-93.52106l4.18616,3.65794a65.03084,65.03084,0,0,0,.53365,86.15416Z" transform="translate(-156.06197 -123.98797)" fill={ fill } />
    <path d="M512.776,533.04037a70.70886,70.70886,0,0,1-52.6-23.51382l4.14055-3.709a65.04336,65.04336,0,0,0,102.47562-7.1378l4.6134,3.102A70.5538,70.5538,0,0,1,512.776,533.04037Z" transform="translate(-156.06197 -123.98797)" fill={ fill } />
    <rect x="533.73553" y="305.03194" width="57.25764" height="57.25764" fill="#e6e6e6" />
    <rect x="119.09476" y="342.2494" width="57.25764" height="57.25764" fill="#e6e6e6" />
    <rect width="703.57565" height="29.89047" fill={ fill } />
    <circle cx="22.21219" cy="15.28159" r="5.53997" fill="#fff" />
    <circle cx="43.24053" cy="15.28159" r="5.53997" fill="#fff" />
    <circle cx="64.26886" cy="15.28159" r="5.53997" fill="#fff" />
</svg>
);

export default SuccessFactorsIcon;

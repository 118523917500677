import React from "react";

import { Icon } from '@types';

const GoogleMarketingPlatformIcon: Icon = ({ fill = "white", ...props }) => (
    <svg { ...props } version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 2277.9 2277.9" style={{enableBackground: 'new 0 0 2277.9 2277.9'}} xmlSpace="preserve">
        {/*<style type="text/css">
            .st0{fill:#FBBC04;}
            .st1{fill:#4184F3;}
            .st2{fill:#34A853;}
            .st3{fill:#EA4335;}
        </style>*/}
        <g>
            <path style={{fill: '#FBBC04'}} d="M1541.8,1944.7c-111.2-111.2-111.2-291.6,0-402.9c-222.5,222.5-583.2,222.5-805.7,0
                c111.2,111.2,111.2,291.6,0,402.9c-111.2,111.2-291.6,111.2-402.9,0c444.3,444.3,1167.2,444.3,1611.5,0
                C1833.4,2055.9,1653,2055.9,1541.8,1944.7L1541.8,1944.7z"/>
            <path style={{fill: '#4184F3'}} d="M1944.7,333.2c-444.3-444.3-1167.2-444.3-1611.5,0c-444.3,444.3-444.3,1167.2,0,1611.5
                c-111.2-111.2-111.2-291.6,0-402.9c111.2-111.2,291.6-111.2,402.9,0c-222.5-222.5-222.5-583.2,0-805.7
                c222.5-222.5,583.2-222.5,805.7,0c111.2,111.2,291.6,111.2,402.9,0C2055.9,624.8,2055.9,444.5,1944.7,333.2L1944.7,333.2z"/>
            <circle style={{fill: '#34A853'}} cx="534.6" cy="1743.2" r="284.9"/>
            <path style={{fill: '#EA4335'}} d="M1944.7,333.2c111.2,111.2,111.2,291.6,0,402.9c-111.2,111.2-291.6,111.2-402.9,0
                c222.5,222.5,222.5,583.2,0,805.7c-111.2,111.2-111.2,291.6,0,402.9c111.2,111.2,291.6,111.2,402.9,0
                C2388.9,1500.4,2388.9,777.5,1944.7,333.2L1944.7,333.2z"/>
        </g>
    </svg>

);

export default GoogleMarketingPlatformIcon;

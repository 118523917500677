import React from "react";

import { Icon } from '@types';

const GoalsIcon: Icon = ({ fill = '#c4c4c4', title = 'goals', ...props }) => (
<svg { ...props } id="b7c4787f-e4a8-4036-aa60-d215daeece2d" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="989.06996" height="610.33611" viewBox="0 0 989.06996 610.33611">
    <title>{ title }</title>
    <rect x="388.42235" y="333.01974" width="748.72329" height="38" transform="matrix(0.8603, 0.50978, -0.50978, 0.8603, 180.54469, -484.50838)" fill="#f2f2f2" />
    <rect x="248.42235" y="365.01974" width="748.72329" height="38" transform="matrix(0.8603, 0.50978, -0.50978, 0.8603, 177.30033, -408.66897)" fill="#f2f2f2" />
    <rect x="124.42235" y="400.01974" width="748.72329" height="38" transform="matrix(0.8603, 0.50978, -0.50978, 0.8603, 177.82043, -340.56695)" fill="#f2f2f2" />
    <circle cx="569.51064" cy="245.75604" r="201.66853" fill="#3f3d56" />
    <path d="M674.97566,427.41442a35.94961,35.94961,0,1,1,35.94961-35.94961A35.99039,35.99039,0,0,1,674.97566,427.41442Zm0-70.14558a34.196,34.196,0,1,0,34.196,34.196A34.23446,34.23446,0,0,0,674.97566,357.26884Z" transform="translate(-105.46502 -144.83195)" fill={ fill } opacity="0.3" />
    <path d="M674.97566,459.85674a68.39194,68.39194,0,1,1,68.39194-68.39193A68.4692,68.4692,0,0,1,674.97566,459.85674Zm0-135.03023a66.6383,66.6383,0,1,0,66.6383,66.6383A66.71355,66.71355,0,0,0,674.97566,324.82651Z" transform="translate(-105.46502 -144.83195)" fill={ fill } opacity="0.3" />
    <path d="M674.97566,487.915a96.45017,96.45017,0,1,1,96.45017-96.45017A96.55931,96.55931,0,0,1,674.97566,487.915Zm0-191.1467a94.69653,94.69653,0,1,0,94.69653,94.69653A94.80393,94.80393,0,0,0,674.97566,296.76828Z" transform="translate(-105.46502 -144.83195)" fill={ fill } opacity="0.3" />
    <path d="M674.97566,524.7414c-73.48888,0-133.2766-59.78728-133.2766-133.27659,0-73.48888,59.78772-133.2766,133.2766-133.2766,73.48931,0,133.2766,59.78772,133.2766,133.2766C808.25226,464.95412,748.465,524.7414,674.97566,524.7414Zm0-264.79955c-72.52215,0-131.523,59.00081-131.523,131.523,0,72.52172,59.00081,131.52295,131.523,131.52295,72.52172,0,131.523-59.00123,131.523-131.52295C806.49862,318.94266,747.49738,259.94185,674.97566,259.94185Z" transform="translate(-105.46502 -144.83195)" fill={ fill } opacity="0.3" />
    <path d="M674.97566,555.43009c-90.4109,0-163.96529-73.55438-163.96529-163.96528s73.55439-163.96529,163.96529-163.96529,163.96528,73.55439,163.96528,163.96529S765.38656,555.43009,674.97566,555.43009Zm0-326.17693c-89.44375,0-162.21165,72.7679-162.21165,162.21165,0,89.44417,72.7679,162.21164,162.21165,162.21164,89.44417,0,162.21165-72.76747,162.21165-162.21164C837.18731,302.02106,764.41983,229.25316,674.97566,229.25316Z" transform="translate(-105.46502 -144.83195)" fill={ fill } opacity="0.3" />
    <circle cx="569.51064" cy="245.75604" r="16.65957" fill={ fill } opacity="0.3" />
    <circle cx="612.4748" cy="293.98113" r="16.65957" fill={ fill } opacity="0.3" />
    <circle cx="495.85778" cy="286.96657" r="16.65957" fill={ fill } opacity="0.3" />
    <circle cx="569.51064" cy="245.75604" r="7.89138" fill="#2f2e41" />
    <circle cx="495.85778" cy="286.96657" r="7.89138" fill="#2f2e41" />
    <circle cx="612.4748" cy="294.85795" r="7.89138" fill="#2f2e41" />
    <circle cx="937.77492" cy="157.19725" r="7.89138" fill={ fill } />
    <polygon points="569.949 246.194 568.195 246.194 568.195 155.882 916.293 155.882 916.293 157.636 569.949 157.636 569.949 246.194" fill="#2f2e41" />
    <path d="M1043.23994,324.82651a22.79732,22.79732,0,1,1,22.79731-22.79731A22.82289,22.82289,0,0,1,1043.23994,324.82651Zm0-43.841a21.04368,21.04368,0,1,0,21.04367,21.04368A21.06779,21.06779,0,0,0,1043.23994,280.98552Z" transform="translate(-105.46502 -144.83195)" fill="#2f2e41" />
    <circle cx="885.16573" cy="384.29356" r="7.89138" fill={ fill } />
    <path d="M990.63075,551.92281a22.79731,22.79731,0,1,1,22.79732-22.79731A22.82261,22.82261,0,0,1,990.63075,551.92281Zm0-43.841a21.04367,21.04367,0,1,0,21.04368,21.04367A21.06778,21.06778,0,0,0,990.63075,508.08183Z" transform="translate(-105.46502 -144.83195)" fill="#2f2e41" />
    <circle cx="251.22508" cy="194.02368" r="7.89138" fill={ fill } />
    <path d="M356.6901,361.65294a22.79732,22.79732,0,1,1,22.79732-22.79732A22.82316,22.82316,0,0,1,356.6901,361.65294Zm0-43.841a21.04368,21.04368,0,1,0,21.04368,21.04367A21.06778,21.06778,0,0,0,356.6901,317.812Z" transform="translate(-105.46502 -144.83195)" fill="#2f2e41" />
    <polygon points="496.735 286.967 494.981 286.967 494.981 198.394 273.132 194.9 273.159 193.147 496.735 196.668 496.735 286.967" fill="#2f2e41" />
    <polygon points="863.245 385.17 611.598 385.17 611.598 294.858 613.352 294.858 613.352 383.417 863.245 383.417 863.245 385.17" fill="#2f2e41" />
    <rect x="889.54983" y="198.40778" width="96.45017" height="6.13774" fill="#2f2e41" />
    <rect x="889.54983" y="215.94417" width="96.45017" height="6.13774" fill="#2f2e41" />
    <rect x="889.54983" y="233.48057" width="96.45017" height="6.13774" fill="#2f2e41" />
    <rect x="836.94065" y="425.50408" width="96.45017" height="6.13774" fill="#2f2e41" />
    <rect x="836.94065" y="443.04048" width="96.45017" height="6.13774" fill="#2f2e41" />
    <rect x="836.94065" y="460.57687" width="96.45017" height="6.13774" fill="#2f2e41" />
    <rect x="203" y="236.11103" width="96.45017" height="6.13774" fill="#2f2e41" />
    <rect x="203" y="253.64742" width="96.45017" height="6.13774" fill="#2f2e41" />
    <rect x="203" y="271.18381" width="96.45017" height="6.13774" fill="#2f2e41" />
    <ellipse cx="577.40202" cy="575.44697" rx="192.02352" ry="6.13774" fill="#f2f2f2" />
    <rect y="608.33611" width="648" height="2" fill="#2f2e41" />
    <path d="M179.45615,324.01867s9.17859,26.12369,3.53023,30.14272,19.06323,36.84111,19.06323,36.84111l29.65392-8.03806-16.239-27.46337s-2.11814-27.46337-2.11814-31.4824S179.45615,324.01867,179.45615,324.01867Z" transform="translate(-105.46502 -144.83195)" fill="#ffb8b8" />
    <path d="M179.45615,324.01867s9.17859,26.12369,3.53023,30.14272,19.06323,36.84111,19.06323,36.84111l29.65392-8.03806-16.239-27.46337s-2.11814-27.46337-2.11814-31.4824S179.45615,324.01867,179.45615,324.01867Z" transform="translate(-105.46502 -144.83195)" opacity="0.1" />
    <path d="M164.04987,510.23371s-2.67936,33.49191,0,50.9077,6.69838,65.64415,6.69838,65.64415,0,91.098,14.73644,92.43768,25.45385,2.67935,26.79353-2.67935-6.69838-8.03806-2.67935-10.71741,5.3587-12.05709,0-22.77451,0-119.2312,0-119.2312l25.45385,68.3235s2.67935,56.26641,5.35871,64.30447-2.67936,22.7745,6.69838,24.11418,20.09515-6.69839,25.45385-9.37774-8.03806-2.67935-5.3587-4.019,10.71741-5.3587,8.03806-6.69838-5.35871-65.64415-5.35871-65.64415-7.36822-137.31684-16.746-142.67555-15.40628,3.87135-15.40628,3.87135Z" transform="translate(-105.46502 -144.83195)" fill="#2f2e41" />
    <path d="M188.164,713.86453V724.582s-12.05708,29.1332,0,29.1332,21.43483,3.019,21.43483-1V716.54389Z" transform="translate(-105.46502 -144.83195)" fill="#2f2e41" />
    <path d="M267.205,713.8455v10.71742s12.05709,29.1332,0,29.1332-21.43483,3.019-21.43483-1V716.52486Z" transform="translate(-105.46502 -144.83195)" fill="#2f2e41" />
    <circle cx="92.7466" cy="174.49786" r="24.11418" fill="#ffb8b8" />
    <path d="M190.8434,364.209s6.69838,20.09515,17.41579,16.07612l10.71741-4.019L237.73207,504.875s-14.73644,21.43482-36.17126,8.03806S190.8434,364.209,190.8434,364.209Z" transform="translate(-105.46502 -144.83195)" fill="#d0cde1" />
    <path d="M209.59887,360.18994l6.02854-12.72693s35.50143,16.746,40.86014,22.10466,5.3587,13.39677,5.3587,13.39677l-9.37773,33.49191s2.67935,73.68221,2.67935,76.36156,9.37773,17.4158,2.67935,12.05709-8.03806-10.71741-13.39676-2.67935-17.4158,17.41579-17.4158,17.41579Z" transform="translate(-105.46502 -144.83195)" fill="#3f3d56" />
    <path d="M256.48755,456.64665l-4.019,37.51094s-24.11418,21.43482-18.75547,22.7745,8.038-4.019,8.038-4.019,9.37774,9.37774,14.73645,4.019S275.243,460.66568,275.243,460.66568Z" transform="translate(-105.46502 -144.83195)" fill="#ffb8b8" />
    <path d="M198.72534,285.2496c-5.34583-.19111-11.06666-.28564-15.57394,2.59515a22.8,22.8,0,0,0-5.38236,5.26887c-4.38983,5.54948-8.18285,12.532-6.55225,19.41738l1.89389-.73864a12.40116,12.40116,0,0,1-1.1962,5.31349c.26669-.77549,1.15983.47814.92072,1.26257l-2.08628,6.8443a6.44383,6.44383,0,0,1,8.2178,4.90354c.23843-7.94972,1.06312-17.06552,7.51192-21.7204,3.2521-2.34744,7.36794-3.06406,11.32795-3.70041,3.6532-.587,7.48294-1.14687,10.98208.0558s6.4789,4.78129,5.68563,8.39532c1.61342-.55578,3.4179.56865,4.21525,2.07737a10.91173,10.91173,0,0,1,.86323,4.99482c1.71983,1.21547,3.67706-1.19812,4.378-3.184,1.64525-4.66158,3.10762-9.62374,2.22132-14.487s-4.84926-9.51094-9.79263-9.52744a3.4325,3.4325,0,0,0,.89269-2.41654l-4.07448-.34427a4.50323,4.50323,0,0,0,2.69093-1.41871C214.23273,290.64079,201.37072,285.34417,198.72534,285.2496Z" transform="translate(-105.46502 -144.83195)" fill="#2f2e41" />
    <path d="M197.54178,356.17091s-10.9058-10.687-14.83064-10.03238-9.28354,10.03238-9.28354,10.03238-32.15223,10.71741-30.81256,21.43482,16.07612,62.9648,16.07612,62.9648,12.05709,62.96479,1.33968,69.66318,50.9077,24.11417,52.24738,16.07611,4.019-88.41864,0-100.47573S197.54178,356.17091,197.54178,356.17091Z" transform="translate(-105.46502 -144.83195)" fill="#3f3d56" />
    <path d="M154.67213,468.70374l18.75547,8.038s36.17127,5.35871,36.17127-9.37773-36.17127-6.69838-36.17127-6.69838l-12.05789-3.85524Z" transform="translate(-105.46502 -144.83195)" fill="#ffb8b8" />
    <path d="M244.43046,377.60573h16.5651s11.5681,50.90771,12.90778,56.26642,4.019,30.81256,2.67935,30.81256-28.1332-5.35871-28.1332-1.33968Z" transform="translate(-105.46502 -144.83195)" fill="#3f3d56" />
    <path d="M158.69116,369.56767l-16.07612,4.019L130.558,444.58956s-4.019,18.75547,2.67936,20.09515,25.45385,12.05708,25.45385,12.05708,2.67935-20.09514,8.03806-20.09514l-13.39676-10.71742,8.038-46.88867Z" transform="translate(-105.46502 -144.83195)" fill="#3f3d56" />
    <path d="M614.465,714.16805h-26v-26h26Zm-24-2h22v-22h-22Z" transform="translate(-105.46502 -144.83195)" fill="#3f3d56" />
    <circle cx="525" cy="559.33611" r="10" fill={ fill } />
</svg>
);

export default GoalsIcon;
